<template>
    <div class='grid'>
        <div class='col-12'>
            <tabela :data='parceria.aditivos'>
                <template #botoes>
                    <CadAditivos v-if='validarExclusaoFuncionario' :parceria='parceria'
                                 :possuiAditivoAberto='possuiAditivoAberto'
                                 @atualizarLista='atualizaLista'></CadAditivos>
                </template>
                <template #conteudo>
                    <!--                    <Column headerStyle='width: 3em'>-->
                    <!--                        <template #body='slotProps'>-->
                    <!--                            <Detalhar :parceriaAditivoId='slotProps.data.id' />-->
                    <!--                        </template>-->
                    <!--                    </Column>-->

                    <Column :sortable='true' field='observacao' header='Motivo/Razão deste aditivo'
                            headerStyle='width: 10em'>
                        <template #body='slotProps'>
                            {{ slotProps.data.observacao }}
                        </template>
                    </Column>

                    <Column :sortable='true' field='ordem' header='Ordem' headerStyle='width: 6em'>
                        <template #body='slotProps'>
                            {{ slotProps.data.ordem }}
                        </template>
                    </Column>

                </template>
            </tabela>
        </div>
    </div>
</template>

<script>
import CadAditivos from '../cadastro/CadAditivos.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },
    },

    components: {
        CadAditivos,
    },

    data() {
        return {
            lista: [],
            validarExclusaoFuncionario: false,
            aditivoPendente: false,

        };
    },

    watch: {
        parceria() {
            this.lista = this.parceria.parceriaFuncionario;
            if (this.parceria.status !== 3 && this.parceria.status !== 4 && this.parceria.status !== 2) {
                this.validarExclusaoFuncionario = true;
            }
            this.possuiAditivoAberto = this.parceria.aditivos.some(aditivo => aditivo.status === 2);
        },
    },

    methods: {
        atualizaLista() {
            this.$emit('atualizarLista');
        },
    },
};
</script>