<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5>Analistas e Gerentes</h5>
                </div>
                <tabela :data="lista">
                    <template #botoes>
                        <CadEnvolvidos
                            :parceria="parceria"
                            :operacao="operacao"
                            :parceriaAditivoId="parceriaAditivo?.id"
                            v-if="validarExclusaoFuncionario"
                            @atualizarLista="atualizarLista()"
                        ></CadEnvolvidos>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <font-awesome-icon
                                    icon="trash-alt"
                                    @click="confirmaDelete(slotProps.data)"
                                    size="lg"
                                    class="icon-button"
                                    title="Deletar"
                                    style="color: red"
                                    v-if="validarExclusaoFuncionario"
                                />
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.funcionario.nome }}
                            </template>
                        </Column>
                        <Column field="setor" header="Email" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.funcionario.email }}
                            </template>
                        </Column>
                        <Column field="cargoDescricao" header="Cargo" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.cargoDescricao }}
                            </template>
                        </Column>
                    </template>
                </tabela>

                {{ validarExclusaoFuncionario }}
            </div>
        </div>
    </div>

    <Dialog v-model:visible="showDialogExcluir" :modal="true" :style="{ width: '350px' }" header="Confirmação">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Não" @click="closeConfirmation(false)" />
            <Button autofocus class="p-button-text" icon="pi pi-check" label="Sim" @click="closeConfirmation(true)" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import CadEnvolvidos from '../cadastro/CadDemaisEnvolvidos.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },

        operacao: {
            type: String,
            default: 'PARCERIA',
        },

        parceriaAditivo: {
            type: Object,
        },
    },

    emits: ['atualizarLista'],

    components: {
        CadEnvolvidos,
    },

    data() {
        return {
            showDialogExcluir: false,
            objExcluir: null,
            lista: [],
            validarExclusaoFuncionario: true,
        };
    },

    watch: {
        parceria() {
            console.log(this.parceria);
            this.lista = this.parceria.parceriaFuncionario;
            if (
                this.parceria.status === 2 ||
                this.parceria.status === 3 ||
                this.parceria.status === 4 ||
                this.parceria.status === 6 ||
                this.parceria.status === 7 ||
                this.parceria.status === 8 ||
                this.parceria.status === 9 ||
                this.parceria.status === 10
            ) {
                this.validarExclusaoFuncionario = false;
            }
        },

        parceriaAditivo() {
            console.log(this.parceriaAditivo);
            this.lista = this.parceriaAditivo.funcionarios;
            if (
                this.parceriaAditivo.status === 0 ||
                this.parceriaAditivo.status === 1 ||
                this.parceriaAditivo.status === 3 ||
                this.parceriaAditivo.status === 6
            ) {
                this.validarExclusaoFuncionario = false;
            }
        },
    },

    methods: {
        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        confirmaDelete(obj) {
            this.objExcluir = {
                idFuncionario: obj.funcionario.id,
            };
            if (this.operacao == 'PARCERIA') {
                this.objExcluir.idParceria = obj.idParceria;
            }
            this.showDialogExcluir = true;
        },

        deletar() {
            this.$store.dispatch('addRequest');
            if (this.operacao == 'PARCERIA') {
                Services.DeletarDemaisEnvolvidos(this.objExcluir).then((response) => {
                    if (response && response.success) {
                        this.atualizarLista();
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                (this.objExcluir.parceriaAditivoId = this.parceriaAditivo.id),
                    Services.DesassociarParceriaAditivoFuncionario(this.objExcluir).then((response) => {
                        if (response && response.success) {
                            this.atualizarLista();
                        }
                        this.$store.dispatch('removeRequest');
                    });
            }
        },

        atualizarLista() {
            if (this.operacao == 'PARCERIA') {
                this.$emit('atualizarLista', true);
            }
            this.$emit('atualizarListaAditivo', true);
        },
    },
};
</script>
