<template>
    <div v-if="desabilita">
        <div class='grid p-fluid pt-4'>
            <div class='field col-4'>
                <h6>Aproxima o Sebrae do Público-Alvo pretendido?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.aproximaSebraePublicoPretendido'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.aproximaSebraePublicoPretendido'
                                   class='icon-button p-text-danger' icon='times-circle'
                                   size='lg' />
            </div>
            <div class='field col-4'>
                <h6>Agrega Expertise Técnica?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.agregaExpertiseTecnica' class='icon-button p-text-success'
                                   icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.agregaExpertiseTecnica' class='icon-button p-text-danger'
                                   icon='times-circle'
                                   size='lg' />
            </div>
    
            <div class='field col-4'>
                <h6>Representação Setorial e Empresarial?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.representacaoSetorialEmpresarial'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.representacaoSetorialEmpresarial'
                                   class='icon-button p-text-danger' icon='times-circle'
                                   size='lg' />
            </div>
            <div class='field col-4'>
                <h6>Promove crédito para as empresas?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.promoveCreditoParaEmpresas'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.promoveCreditoParaEmpresas' class='icon-button p-text-danger'
                                   icon='times-circle'
                                   size='lg' />
            </div>
            <div class='field col-4'>
                <h6>Promove Mercado para os Pequenos Negócios?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.promoveMercadoPequenosNegocios'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.promoveMercadoPequenosNegocios'
                                   class='icon-button p-text-danger' icon='times-circle'
                                   size='lg' />
            </div>
            <div class='field col-4'>
                <h6>Tem foco em Inovação ou Cliente do Futuro?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.temFocoInovacaoEmpreendedorismo'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.temFocoInovacaoEmpreendedorismo'
                                   class='icon-button p-text-danger' icon='times-circle'
                                   size='lg' />
            </div>
            <div class='field col-4'>
                <h6>Conexão Corporativa com Empresa de Grande Porte ou GOV?</h6>
            </div>
            <div class='field col-2'>
                <font-awesome-icon v-if='!!parceria.conexaoCorporativaEmpresaGrandePorteGov'
                                   class='icon-button p-text-success' icon='check-circle'
                                   size='lg' />
                <font-awesome-icon v-if='!parceria.conexaoCorporativaEmpresaGrandePorteGov'
                                   class='icon-button p-text-danger' icon='times-circle'
                                   size='lg' />
            </div>
        </div>
    </div>
    <div v-else>
        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <label for='accept'>Aproxima o Sebrae do Público-Alvo pretendido?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='aproximaSebraePublicoPretendido' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Agrega Expertise Técnica?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='agregaExpertiseTecnica' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Representação Setorial e Empresarial?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='representacaoSetorialEmpresarial' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Promove crédito para as empresas?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='promoveCreditoParaEmpresas' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Promove Mercado para os Pequenos Negócios?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='promoveMercadoPequenosNegocios' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Tem foco em Inovação ou Cliente do Futuro?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='temFocoInovacaoEmpreendedorismo' :binary='true' />
            </div>
            <div class='field col-3'>
                <label for='accept'>Conexão Corporativa com Empresa de Grande Porte ou GOV?</label>
            </div>
            <div class='field col-1'>
                <Checkbox v-model='conexaoCorporativaEmpresaGrandePorteGov' :binary='true' />
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
export default {
    props: {
        parceria: {
            type: Object,
            required: true,
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    data() {
        return {
            aproximaSebraePublicoPretendido: false,
            agregaExpertiseTecnica: false,
            representacaoSetorialEmpresarial: false,
            promoveCreditoParaEmpresas: false,
            promoveMercadoPequenosNegocios: false,
            temFocoInovacaoEmpreendedorismo: false,
            conexaoCorporativaEmpresaGrandePorteGov: false,
        };
    },
    methods: {
        atualizaValores() {
            this.$emit('update:valores', {
            isValid: !this.v$.$invalid,
            aproximaSebraePublicoPretendido: this.aproximaSebraePublicoPretendido,
            agregaExpertiseTecnica: this.agregaExpertiseTecnica,
            representacaoSetorialEmpresarial: this.representacaoSetorialEmpresarial,
            promoveCreditoParaEmpresas: this.promoveCreditoParaEmpresas,
            promoveMercadoPequenosNegocios: this.promoveMercadoPequenosNegocios,
            temFocoInovacaoEmpreendedorismo: this.temFocoInovacaoEmpreendedorismo,
            conexaoCorporativaEmpresaGrandePorteGov: this.conexaoCorporativaEmpresaGrandePorteGov,
            });
        },
    },
    watch: {
        parceria() {
            this.aproximaSebraePublicoPretendido = this.parceria?.aproximaSebraePublicoPretendido;
            this.agregaExpertiseTecnica = this.parceria?.agregaExpertiseTecnica;
            this.representacaoSetorialEmpresarial = this.parceria?.representacaoSetorialEmpresarial;
            this.promoveCreditoParaEmpresas = this.parceria?.promoveCreditoParaEmpresas;
            this.promoveMercadoPequenosNegocios = this.parceria?.promoveMercadoPequenosNegocios;
            this.temFocoInovacaoEmpreendedorismo = this.parceria?.temFocoInovacaoEmpreendedorismo;
            this.conexaoCorporativaEmpresaGrandePorteGov = this.parceria?.conexaoCorporativaEmpresaGrandePorteGov;
        },
        aproximaSebraePublicoPretendido() {
            this.atualizaValores();
        },
        agregaExpertiseTecnica() {
            this.atualizaValores();
        },
        representacaoSetorialEmpresarial() {
            this.atualizaValores();
        },
        promoveCreditoParaEmpresas() {
            this.atualizaValores();
        },
        promoveMercadoPequenosNegocios() {
            this.atualizaValores();
        },
        temFocoInovacaoEmpreendedorismo() {
            this.atualizaValores();
        },
        conexaoCorporativaEmpresaGrandePorteGov() {
            this.atualizaValores();
        },
    },
};
</script>