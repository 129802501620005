<template>
    <div class='grid p-fluid px-2 py-4'>
        <div class='formgrid grid col-12'>
            <div class='field col-3'>
                <BInputNumeric v-model='valorReceita'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorReceita.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorReceita.$error'
                               label='Recebimento de Receitas'
                               required @blur='v$.valorReceita.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorContrapartidaFinanceira'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorContrapartidaFinanceira.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorContrapartidaFinanceira.$error'
                               label='Contrapartida Financeira'
                               required @blur='v$.valorContrapartidaFinanceira.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorContrapartidaEconomica'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorContrapartidaEconomica.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorContrapartidaEconomica.$error'
                               label='Contrapartida Econômica'
                               required @blur='v$.valorContrapartidaEconomica.$touch()' />
            </div>
            <div class='field col-3'>
                <BInputNumeric v-model='valorAporteSebrae'
                               :desabilita='desabilita'
                               :mensagemErro="v$.valorAporteSebrae.$silentErrors[0]?.$message || ''"
                               :temErro='v$.valorAporteSebrae.$error'
                               label='Aporte Sebrae'
                               required @blur='v$.valorAporteSebrae.$touch()' />
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, minValue, required } from '@vuelidate/validators';

export default {
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    props: {
        parceria: {
            type: Object,
            default: null,
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            valorReceita: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorContrapartidaFinanceira: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorContrapartidaEconomica: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
            valorAporteSebrae: {
                required: helpers.withMessage('Campo obrigatório', required),
                minValue: helpers.withMessage('Valor mínimo é R$ 0,00', minValue(0)),
            },
        };
    },
    data() {
        return {
            valorReceita: null,
            valorContrapartidaFinanceira: null,
            valorContrapartidaEconomica: null,
            valorAporteSebrae: null,
        };
    },
    methods: {
        atualizaValores() {
            this.$emit('update:valores', {
                isValid: !this.v$.$invalid,
                valorReceita: this.valorReceita,
                valorContrapartidaFinanceira: this.valorContrapartidaFinanceira,
                valorContrapartidaEconomica: this.valorContrapartidaEconomica,
                valorAporteSebrae: this.valorAporteSebrae,
            });
        },
    },
    watch: {
        parceria() {
            this.valorReceita = this.parceria?.valorReceita;
            this.valorContrapartidaFinanceira = this.parceria?.valorContrapartidaFinanceira;
            this.valorContrapartidaEconomica = this.parceria?.valorContrapartidaEconomica;
            this.valorAporteSebrae = this.parceria?.valorAporteSebrae;
        },
        valorReceita() {
            this.atualizaValores();
        },
        valorContrapartidaFinanceira() {
            this.atualizaValores();
        },
        valorContrapartidaEconomica() {
            this.atualizaValores();
        },
        valorAporteSebrae() {
            this.atualizaValores();
        },
    },
};
</script>