<template>
    <div style='display: flex;flex-direction: column;gap: 10px;'>
        <Header :breadcrumbs='breadcrumbs' :home='home' :steps='steps' />

        <div class='card'>
            <slot />
        </div>
    </div>
</template>
<script>
import Header from './Header.vue';

export default ({
    components: {
        Header,
    },
    props: {
        home: {
            type: String,
            default: '',
        },
        breadcrumbs: {
            type: Array,
            required: true,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },
});
</script>

