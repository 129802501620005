<template>
    <Button
        label="Adicionar"
        icon="pi pi-plus"
        class="ml-2"
        @click="showDialog()"
        v-if="parceiro?.parceriaFuncionario == null && parceria?.status != 3 && parceria?.status != 4"
    />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '40vw' }" :maximizable="false">
        <template #header>
            <h3>Cadastro - Analistas e Gerentes da Parceria</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label class="required">Funcionário</label>
                <Dropdown v-model="nome" :options="combo" :filter="true" optionLabel="nome" optionValue="nome" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-6">
                <label>E-mail</label>
                <InputText type="text" v-model="demaisEnvolvidos.email" disabled />
            </div>
            <div class="field col-6">
                <label>Cargo</label>
                <Dropdown v-model="demaisEnvolvidos.cargo" :options="cargos" :filter="true" optionLabel="descricao" optionValue="id" />
            </div>
        </div>

        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        parceria: {
            type: Object,
        },

        operacao: {
            type: String,
            default: 'PARCERIA',
        },

        parceriaAditivoId: {
            type: Number,
        },
    },

    emits: ['atualizarLista'],

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            demaisEnvolvidos: {
                nome: { required },
                cargo: { required },
            },
        };
    },

    watch: {
        nome() {
            let funcionario = this.combo.find((e) => e.nome == this.nome);
            this.demaisEnvolvidos.nome = funcionario?.nome;
            this.demaisEnvolvidos.email = funcionario?.email;
        },
    },

    data() {
        return {
            display: false,
            combo: [],
            nome: null,
            demaisEnvolvidos: {
                id: 0,
                nome: null,
                email: null,
                cargo: 3,
            },
            cargos: [
                { id: 3, descricao: 'ANALISTA' },
                { id: 2, descricao: 'GERENTE' },
                // { id: 1, descricao: 'DIRETOR' },
            ],
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.carregaCombo();
            this.limpar();
        },

        limpar() {
            this.demaisEnvolvidos = {
                id: 0,
                nome: null,
                email: null,
                cargo: 3,
            };
            this.nome = null;
        },

        closeDialog() {
            this.limpar();
            this.display = false;
        },

        carregaCombo() {
            this.$store.dispatch('addRequest');
            Services.SelectAllFuncionario(this.parceria?.id, this.parceriaAditivoId)
                .then((response) => {
                    if (response && response.success) {
                        this.combo = response.data;
                    } else {
                        this.combo = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            if (this.operacao == 'PARCERIA') {
                let associarParceriaFuncionarioDto = {
                    IdParceria: this.parceria.id,
                    Nome: this.demaisEnvolvidos.nome,
                    Email: this.demaisEnvolvidos.email,
                    Cargo: this.demaisEnvolvidos.cargo,
                };
                Services.InserirDemaisEnvolvidos(associarParceriaFuncionarioDto)
                    .then((response) => {
                        if (response && response.success) {
                            this.$emit('atualizarLista');
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            } else {
                let associarParceriaAditivoFuncionarioDto = {
                    parceriaAditivoId: this.parceriaAditivoId,
                    Nome: this.demaisEnvolvidos.nome,
                    Email: this.demaisEnvolvidos.email,
                    Cargo: this.demaisEnvolvidos.cargo,
                };

                Services.InserirParceriaAditivoFuncionario(associarParceriaAditivoFuncionarioDto)
                    .then((response) => {
                        if (response && response.success) {
                            this.$emit('atualizarLista');
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            }
            this.closeDialog();
        },
    },
};
</script>