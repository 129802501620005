<template>
    <div class="grid">
        <div class="col-12">
            <tabela :data="lista">
                <template #botoes>
                    <h7 class="mr-8"> Aporte Sebrae {{ this.parceria.valorAporteSebraeFormatado }} </h7>
                    <h7
                        :class="{
                            'valor-verde': this.parceria.valorTotalAcoes <= this.parceria.valorAporteSebrae,
                            'valor-vermelho': this.parceria.valorTotalAcoes > this.parceria.valorAporteSebrae,
                        }"
                        class="mr-8"
                    >
                        Valor Somatório Ações {{ this.parceria.valorTotalAcoesFormatado }}
                    </h7>
                    <ExportarAcoesDaParceriaEmExcel :habilita="lista.length > 0" :idParceria="parceria.id" />
                    <CadAcoes
                        v-if="validarExclusaoAcoes"
                        :defaultValue="acao"
                        :show="abrirFormularioAcao"
                        @close="onCloseFormularioAcao"
                        :parceria="parceria"
                        :parceriaAditivo="parceriaAditivo"
                        @atualizarLista="atualizarLista()"
                    ></CadAcoes>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex gap-1">
                                <font-awesome-icon
                                    v-if="podeCriarAlterarOuExcluirAcao"
                                    class="icon-button"
                                    icon="trash-alt"
                                    size="lg"
                                    style="color: red"
                                    title="Deletar"
                                    @click="confirmaDelete(slotProps.data)"
                                />
                                <div class="mr-3"></div>
                                <font-awesome-icon
                                    v-if="podeCriarAlterarOuExcluirAcao"
                                    class="icon-button p-text-info"
                                    icon="pen"
                                    size="lg"
                                    title="Editar"
                                    @click="onAbrirFormularioAcao(slotProps.data)"
                                />
                            </div>
                        </template>
                    </Column>
                    <Column :sortable="true" field="descricaoAcao" header="Ação">
                        <template #body="slotProps">
                            {{ slotProps.data.descricaoAcao }}
                        </template>
                    </Column>
                    <Column :sortable="true" field="descricaoAcao" header="Eixo">
                        <template #body="slotProps">
                            {{ slotProps.data.eixoDescricao }}
                        </template>
                    </Column>
                    <Column :sortable="true" field="descricaoAcao" header="SubEixo">
                        <template #body="slotProps">
                            {{ slotProps.data.subEixoDescricao }}
                        </template>
                    </Column>
                    <!-- <Column :sortable='true' field='valorCliente' header='Contrapartida Financeira do Parceiro'>
                        <template #body='slotProps'>
                            {{ slotProps.data.valorClienteFormatado }}
                        </template>
                    </Column> -->
                    <Column :sortable="true" field="valorSebrae" header="Valor Sebrae">
                        <template #body="slotProps">
                            {{ slotProps.data.valorSebraeFormatado }}
                        </template>
                    </Column>
                    <!-- <Column :sortable='true' field='valorTotal' header='Valor Total'>
                        <template #body='slotProps'>
                            {{ slotProps.data.valorTotalFormatado }}
                        </template>
                    </Column> -->
                </template>
            </tabela>
        </div>
    </div>

    <Dialog v-model:visible="showDialogExcluir" :modal="true" :style="{ width: '350px' }" header="Confirmação">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Não" @click="closeConfirmation(false)" />
            <Button autofocus class="p-button-text" icon="pi pi-check" label="Sim" @click="closeConfirmation(true)" />
        </template>
    </Dialog>
</template>

<style>
.valor-verde {
    color: rgb(86, 194, 91);
}

.valor-vermelho {
    color: red;
}
</style>

<script>
import Services from '../../service.js';
import CadAcoes from '../cadastro/CadAcoes.vue';
import ExportarAcoesDaParceriaEmExcel from './ExportarAcoesDaParceriaEmExcel.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },

        parceriaAditivo: {
            type: Object,
        },

        operacao: {
            type: String,
            default: 'PARCERIA',
        },
    },

    emits: ['atualizarLista', 'atualizarListaAditivo'],

    components: {
        CadAcoes,
        ExportarAcoesDaParceriaEmExcel,
    },

    data() {
        return {
            showDialogExcluir: false,
            objExcluir: null,
            lista: [],
            validarExclusaoAcoes: true,
            abrirFormularioAcao: false,
            acao: null,
        };
    },

    methods: {
        onCloseFormularioAcao() {
            this.abrirFormularioAcao = false;
            this.acao = null;
        },
        onAbrirFormularioAcao(acao) {
            this.abrirFormularioAcao = true;
            this.acao = acao;
        },
        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        confirmaDelete(obj) {
            if (this.operacao === 'PARCERIA') {
                this.objExcluir = obj.acaoId;
            } else {
                this.objExcluir = {
                    parceriaAditivoAcaoId: obj.id,
                    parceriaAditivoId: obj.parceriaAditivoId,
                };
            }
            this.showDialogExcluir = true;
        },

        deletar() {
            this.$store.dispatch('addRequest');
            if (this.operacao === 'PARCERIA') {
                Services.ExcluirAcaoParceria(this.objExcluir).then((response) => {
                    if (response && response.success) {
                        this.atualizarLista();
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                Services.RemoverParceriaAditivoAcao(this.objExcluir).then((response) => {
                    if (response && response.success) {
                        this.atualizarListaAditivo();
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },

        atualizarLista() {
            this.$emit('atualizarLista');
        },
        atualizarListaAditivo() {
            this.$emit('atualizarListaAditivo', true);
        },
    },

    watch: {
        parceria() {
            this.lista = this.parceria.acoes;
            if (
                this.parceria.status === 2 ||
                this.parceria.status === 3 ||
                this.parceria.status === 4 ||
                this.parceria.status === 6 ||
                this.parceria.status === 7 ||
                this.parceria.status === 8 ||
                this.parceria.status === 9 ||
                this.parceria.status === 10
            ) {
                this.validarExclusaoAcoes = false;
            }
        },

        parceriaAditivo() {
            this.lista = this.parceriaAditivo.acoes;
            if (
                this.parceriaAditivo.status === 0 ||
                this.parceriaAditivo.status === 1 ||
                this.parceriaAditivo.status === 3 ||
                this.parceriaAditivo.status === 6
            ) {
                this.validarExclusaoAcoes = false;
            }
        },
    },

    computed: {
        podeCriarAlterarOuExcluirAcao() {
            return this.parceria.aditivoPendente || this.parceria.status === 0;
        },
    },
};
</script>
