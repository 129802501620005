<template>
    <div class='formgrid grid p-fluid'>
        <div class='field col-12'>
            <label>Objeto da Parceria</label>
            <Editor ref='editorObjeto' v-model='objeto' editorStyle='height: 220px' readonly/>
        </div>
    </div>
    <div class='formgrid grid p-fluid'>
        <div class='field col-6'>
            <label>Compete ao Sebrae</label>
            <Editor ref='editorCompeteSebrae' v-if="!desabilita" v-model='competeSebrae' editorStyle='height: 220px' rows='5' type='text' />
            <Editor ref='editorCompeteSebrae' v-else v-model='competeSebrae' editorStyle='height: 220px' rows='5' type='text' readonly/>
        </div>
        <div class='field col-6'>
            <label>Compete ao Parceiro</label>
            <Editor ref='editorCompeteParceiro' v-if="!desabilita" v-model='competeParceiro' editorStyle='height: 220px' rows='5' type='text' />
            <Editor ref='editorCompeteParceiro' v-else v-model='competeParceiro' editorStyle='height: 220px' rows='5' type='text' readonly/>
        </div>
    </div>
    <div class='formgrid grid p-fluid'>
        <div class='field col-12'>
            <label>Cláusulas da Parceria</label>
            <Editor ref='editorClausulas' v-model='clausulas' editorStyle='height: 320px' readonly/>
        </div>
    </div>
</template>
<script>

import { useVuelidate } from '@vuelidate/core';
import Editor from 'primevue/editor';

export default {
    components: {
        Editor,
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    props: {
        parceria: {
            type: Object,
            default: null,
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            competeSebrae: {},
            competeParceiro: {},
        };
    },
    data() {
        return {
            objeto: '',
            clausulas: '',
            competeSebrae: '',
            competeParceiro: '',
        };
    },
    methods: {
        atualizaValores() {
            this.$emit('update:valores', {
                isValid: !this.v$.$invalid,
                competeSebrae: this.competeSebrae,
                competeParceiro: this.competeParceiro,
            });
        },
    },
    watch: {
        parceria() {
            this.objeto = this.parceria?.objeto;
            this.clausulas = this.parceria?.clausulas;
            this.competeSebrae = this.parceria?.competeSebraeSemReplace;
            this.competeParceiro = this.parceria?.competeParceiroSemReplace;
        },
        competeSebrae() {
            this.atualizaValores();
        },
        competeParceiro() {
            this.atualizaValores();
        },
    },
};
</script>