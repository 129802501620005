<template>
    <Button label="Adicionar" icon="pi pi-plus" class="ml-2" @click="mostrarDialog()" v-if="mostrarBotao" />
    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '40vw' }" :maximizable="false">
        <template #header>
            <h3>Cadastro - Testemunhas da Parceria</h3>
        </template>
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label class='required'>Testemunha</label>
                <Dropdown v-model="idTestemunha"  :filter="true" :options="testemunhas" optionLabel="nome" optionValue="id" />
            </div>
        </div>
        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="fecharDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        parceria: {
            type: Object,
        },
        testemunhasLista: {
            type: Array,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            idTestemunha: { required },
        };
    },

    data() {
        return {
            display: false,
            testemunhas: [],
            idTestemunha: null,
        };
    },

    methods: {
        mostrarDialog() {
            this.display = true;
            this.carregaCombo();
        },

        fecharDialog() {
            this.display = false;
            this.idTestemunha = null;
        },

        carregaCombo() {
            this.$store.dispatch('addRequest');
            Services.obterTestemunhas().then((response) => {
                if (response && response.success) {
                    this.testemunhas = response.data;
                } else {
                    this.testemunhas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvar() {
            let testemunhaDto = {
                idParceria: this.parceria.id,
                idTestemunha: this.idTestemunha,
            };
            this.$store.dispatch('addRequest');
            Services.inserirTestemunhaParceria(testemunhaDto).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }
                this.$store.dispatch('removeRequest');
            });
            this.fecharDialog();
        },
    },

    computed: {
        mostrarBotao() {
            console.log(this.parceria);
            return this.parceria.status != 3 && this.parceria.status != 4 && this.parceria.status != 2;
        },
    }
};
</script>