<template>
    <Button label="Adicionar" icon="pi pi-plus" class="ml-2" @click="showDialog()" v-if="parceria.status != 3 && parceria.status != 4 && parceria.status != 2"/>

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '40vw' }" :maximizable="false">
        <template #header>
            <h3>Cadastro - Unidades Regionais da Parceria</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>Unidades Regionais</label>
                <Dropdown v-model="unidadesparceria.idUnidadeRegional" :filter="true" :options="combo" optionLabel="descricao" optionValue="id" />
            </div>
        </div>

        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>



<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        parceria: {
            type: Object,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            idUnidadeRegional: { required },
        };
    },

    watch: {
        'unidadesparceria.idUnidadeRegional'() {
            this.idUnidadeRegional = null;
            if (this.unidadesparceria.idUnidadeRegional > 0) {
                this.idUnidadeRegional = this.unidadesparceria.idUnidadeRegional;
            }
        },
    },

    data() {
        return {
            display: false,
            combo: [],
            idUnidadeRegional: null,
            unidadesparceria: {
                idParceria: 0,
                idUnidadeRegional: 0,
                unidadeRegional: {},
            },
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.carregaCombo();
            this.unidadesparceria.idUnidadeRegional = 0;
            this.idUnidadeRegional = null;
        },

        closeDialog() {
            this.display = false;
        },

        carregaCombo() {
            this.$store.dispatch('addRequest');
            Services.SelectAllUnidadesRegionais().then((response) => {
                if (response && response.success) {
                    this.combo = response.data;
                } else {
                    this.combo = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvar() {
            this.unidadesparceria.idParceria = this.parceria.id;

            this.$store.dispatch('addRequest');
            Services.InserirUnidadesRegionaisParceria(this.unidadesparceria).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }
                this.$store.dispatch('removeRequest');
            });

            this.closeDialog();
        },
    },
};
</script>