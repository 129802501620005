<template>
    <Button v-if='podeCriarAlterarOuExcluirParcela' class='ml-2' icon='pi pi-plus' label='Adicionar'
            @click='showDialog()' />

    <Dialog v-model:visible='openDialog' :maximizable='false' :style="{ width: '40vw' }" no-close-on-backdrop>
        <template #header>
            <h3>Cadastro - Parcelas</h3>
        </template>

        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <label class='required'>Parcela</label>
                <InputText v-model='parcelas.parcela' type='text' />
            </div>
            <div class='field col-4' style='margin-top:25px'>
                <detalhe titulo='Valor Receita: '>{{ valorReceita }}</detalhe>
            </div>
            <div class='field col-5' style='margin-top:25px'>
                <detalhe v-if='valorReceita === somatorioParcelas' size='150' titulo='Somatório Parcelas: '>
                    <Tag severity='success'>{{ somatorioParcelas }}</Tag>
                </detalhe>
                <detalhe v-if='valorReceita !== somatorioParcelas' size='150' titulo='Somatório Parcelas: '>
                    <Tag severity='danger'>{{ somatorioParcelas }}</Tag>
                </detalhe>
            </div>
        </div>
        <div class='formgrid grid p-fluid'>
            <div class='field col-3'>
                <label class='required'>Valor</label>
                <div class='p-inputgroup'>
                    <InputNumber v-model='parcelas.valor' :maxFractionDigits='2' :minFractionDigits='2'
                                 mode='decimal' />
                </div>
            </div>
            <div class='field col-3'>
                <label class='required'>Vencimento</label>
                <div class='p-inputgroup'>
                    <Calendar v-model='parcelas.dataVencimento' :showIcon='true' />
                </div>
            </div>
        </div>
        <div class='formgrid grid p-fluid'>
            <div class='field col-12'>
                <label class='required'>Forma de Pagamento</label>
                <InputText v-model='parcelas.formaPagamento' type='text' />
            </div>
        </div>

        <template #footer>
            <Button :disabled='v$.$invalid' label='Salvar' @click='salvar()' />
            <Button autofocus label='Fechar' @click='closeDialog' />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        parceria: {
            type: Object,
        },
        parceriaAditivo: {
            type: Object,
        },
        operacao: {
            type: String,
            default: 'PARCERIA',
        },
        show: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: Object,
        },
    },

    emits: ['atualizarLista', 'close'],

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            parcela: { required },
            dataVencimento: { required },
            formaPagamento: { required },
        };
    },

    data() {
        return {
            display: false,
            parcela: null,
            dataVencimento: null,
            formaPagamento: null,
            parcelas: {
                id: 0,
                idParceria: 0,
                parcela: '',
                valor: 0,
                dataVencimento: null,
                formaPagamento: '',
                parceriaAditivoId: null,
            },
            valorReceita: 0,
            somatorioParcelas: 0,
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.parcelas.id = 0;
            this.parcela = null;
            this.dataVencimento = null;
            this.formaPagamento = null;
            this.limpar();
        },

        limpar() {
            this.parcelas.id = 0;
            this.parcelas.idParceria = 0;
            this.parcelas.parcela = '';
            this.parcelas.valor = 0;
            this.parcelas.dataVencimento = null;
            this.parcelas.formaPagamento = '';

        },

        closeDialog() {
            this.$emit('close');
            this.display = false;
        },

        salvar() {
            this.$store.dispatch('addRequest');
            this.parcelas.idParceria = this.parceria.id;
            this.parcelas.dataVencimento = this.$moment(this.parcelas.dataVencimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
            Services.InserirOuAlterarParcelaParceria(this.parcelas.id, this.parcelas).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }}).finally(() => {
                this.closeDialog(); 
                this.$store.dispatch('removeRequest');
                this.limpar();
                });
        },
    },

    computed: {
        openDialog() {
            return this.show || this.display;
        },
        podeCriarAlterarOuExcluirParcela() {
            return this.parceria.aditivoPendente || this.parceria?.status === 0;
        },
    },

    watch: {
        'defaultValue'() {
            this.parcelas.parcela = this.defaultValue?.parcela;
            this.parcelas.valor = this.defaultValue?.valor;
            this.parcelas.dataVencimento = this.$moment(this.defaultValue?.dataVencimento).format('DD/MM/YYYY');
            this.parcelas.formaPagamento = this.defaultValue?.formaPagamento;
            this.parcelas.id = this.defaultValue?.id;
        },
        'parcelas.parcela'() {
            this.parcela = null;
            if (this.parcelas.parcela !== '') {
                this.parcela = this.parcelas.parcela;
            }
        },
        'parcelas.dataVencimento'() {
            this.dataVencimento = null;
            if (this.parcelas.dataVencimento !== '') {
                this.dataVencimento = this.parcelas.dataVencimento;
            }
        },
        'parcelas.formaPagamento'() {
            this.formaPagamento = null;
            if (this.parcelas.formaPagamento !== '') {
                this.formaPagamento = this.parcelas.formaPagamento;
            }
        },

        'parceria.parcelas'() {
            this.somatorioParcelas = 0;
            this.valorReceita = 0;

            if (this.parceria.parcelas != null) {
                this.valorReceita = this.parceria?.valorReceita;

                for (const parcela of this.parceria.parcelas) {
                    this.somatorioParcelas += parcela.valor;
                }
                this.somatorioParcelas = this.somatorioParcelas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                });
                this.valorReceita = this.valorReceita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        },

        parceriaAditivo() {
            this.somatorioParcelas = 0;
            this.valorReceita = 0;
            if (this.parceriaAditivo.parcelas != null) {
                this.valorReceita = this.parceriaAditivo.valorReceita;

                for (const parcela of this.parceriaAditivo.parcelas) {
                    this.somatorioParcelas += parcela.valor;
                }
                this.somatorioParcelas = this.somatorioParcelas.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                });
                this.valorReceita = this.valorReceita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        },

    },

    mounted() {
        this.openDialog = this.show;
    },
};
</script>