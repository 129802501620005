<template>
    <div class='grid pt-4'>
        <div class='col-6'>
            <div class='mb-4'>
                <detalhe :size='sizeForm' titulo='Nº Acordo: '> {{ parceria.numeroAcordo }}</detalhe>
                <detalhe :size='sizeForm' titulo='Parceria de: '> {{ parceria.dataInicioParceriaFormatada }}
                    até
                    {{ parceria.dataFimParceriaFormatada }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Nome Projeto: '> {{ parceria.nomeProjeto }} -
                    {{ parceria.evento }}
                </detalhe>
                <!-- <detalhe :size='sizeForm' titulo='Modalidade de Apoio: '>
                    {{ parceria.modalidadeApoio?.descricao }}
                </detalhe> -->
                <detalhe :size='sizeForm' titulo='Unidade Regional: '> {{ parceria.unidadeRegional }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Status: '>
                    <status :status='parceria.statusDescricao'></status>
                </detalhe>
                <detalhe :size='sizeForm' titulo='Parceiro: '> {{ parceria.parceiro.id }} ||
                    {{ parceria.parceiro.cpfCnpj }} || {{ parceria.parceiro.razaoSocial }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Atuação Principal: '>
                    {{ parceria.parceiro.areaAtuacaoPrincipal.descricao }}
                </detalhe>
            </div>
        </div>
        <div class='col-6'>
            <div class='mb-4'>
                <detalhe :size='sizeForm' titulo='Gerente: '> {{ parceria.gerente }}</detalhe>
                <detalhe :size='sizeForm' titulo='Analista: '> {{ parceria.analista }}</detalhe>
                <detalhe :size='sizeForm' titulo='Setor: '> {{ parceria.parceiro.setor.descricao }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Cidade\Estado do Evento: '> {{ parceria.cidadeEstadoEvento
                    }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Execução de: '> {{ parceria.dataExecussaoInicialFormatada
                    }} até
                    {{ parceria.dataExecussaoFinalFormatada }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Tags: '> {{ parceria.tags }}</detalhe>
                <detalhe :size='sizeForm' titulo='Abrangência: '> {{ parceria.abrangencia }}</detalhe>
                <detalhe :size='sizeForm' titulo='Instrumento de Acordo: '> {{ parceria.instrumento }}
                </detalhe>
                <detalhe :size='sizeForm' titulo='Faz parte da Rede de Atendimento: '>
                    {{ parceria.parceiro.redeAtendimentoFormatada }}
                </detalhe>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        parceria: {
            type: Object,
        },
    },

    data() {
        return {
            sizeForm: '200',
        };
    },
};
</script>