<template>
    <Button v-if='podeCriarAlterarOuExcluirAcao' class="ml-2" icon="pi pi-plus"
    label="Adicionar"  @click="showDialog()" />

    <Dialog v-model:visible='openDialog' no-close-on-backdrop :style="{ width: '50vw' }" :maximizable="false" >
        <template #header>
            <h3>Cadastro - Ações</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label class='required'>Descrição</label>
                <InputText type="text" v-model="descricaoAcao" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class='field col-6'>
                <label class='required'>Eixo</label>
                <Dropdown v-model='eixo' :filter='true' :options='comboEixos' optionLabel='label' optionValue='key' />
            </div>
            <div v-if='eixo == 6' class='field col-6'>
                <label class='required'>Sub Eixos</label>
                <Dropdown v-model='subEixo' :filter='true' :options='comboSubEixos' optionLabel='label'
                optionValue='key' />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <!-- <div class="field col-4">
                <label>Contrapartida Financeira do Parceiro</label>
                <div class="p-inputgroup">
                    <InputNumber v-model="valorCliente" mode="decimal" :minFractionDigits="2" />
                </div>
            </div> -->
            <div class="field col-4">
                <label>Valor Sebrae</label>
                <div class="p-inputgroup">
                    <InputNumber v-model="valorSebrae" mode="decimal" :minFractionDigits="2" />
                </div>
            </div>
            <div class="field col-4">
                <label>Valor Total</label>
                <div class="p-inputgroup">
                    <InputNumber v-model="valorTotal" mode="decimal" :minFractionDigits="2" disabled="true"/>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid"/>
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    props: {
        parceria: {
            type: Object,
        },

        operacao: {
            type: String,
            default: 'PARCERIA',
        },

        parceriaAditivo: {
            type: Object,
        },

        show: {
            type: Boolean,
            default: false,
        },

        defaultValue: {
            type: Object,
        },
    },

    emits: ['atualizarLista', 'close'],

    validations() {
        return {
            descricaoAcao: { required },
            eixo: { required },
        };
    },

    setup() {
        return { v$: useVuelidate() };
    },

    data() {
        return {
            descricaoAcao: null,
            // valorCliente: null,
            valorSebrae: null,
            valorTotal: null,
            display: 0,
            acao: null,
            id: 0,
            eixo: null,
            subEixo: null,
            comboEixos: [
                {
                    key: 1,
                    label: 'Ambiente Competitivo e Gestão Empresarial',
                },
                {
                    key: 2,
                    label: 'Inovação Empresarial',
                },
                {
                    key: 3,
                    label: 'Estudos e Diagnósticos',
                },
                {
                    key: 4,
                    label: 'Acesso a Mercados',
                },
                {
                    key: 5,
                    label: 'Capacitação Técnica',
                },
                {
                    key: 6,
                    label: 'Políticas Públicas e Ambiente de Negócios - PP/AN',
                },
            ],
            comboSubEixos: [
                {
                    key: 1,
                    label: 'PP/AN - Gestão Municipal',
                },
                {
                    key: 2,
                    label: 'PP/AN - Lideranças Locais',
                },
                {
                    key: 3,
                    label: 'PP/NA - Desburocratização',
                },
                {
                    key: 4,
                    label: 'PP/AN - Sala do Empreendedor',
                },
                {
                    key: 5,
                    label: 'PP/AN - Compras Governamentais',
                },
                {
                    key: 6,
                    label: 'PP/AN - Empreendedorismo na Escola',
                },
                {
                    key: 7,
                    label: 'PP/AN - Inclusão Produtiva',
                },
                {
                    key: 8,
                    label: 'PP/AN - Marketing Territorial e Setores Produtivos',
                },
                {
                    key: 9,
                    label: 'PP/AN - Cooperativismo e Crédito',
                },
                {
                    key: 10,
                    label: 'PP/AN - Inovação e Sustentabilidade',
                },
            ],
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.limpar();
        },

        limpar() {
            this.descricaoAcao = '';
            // this.valorCliente = 0;
            this.valorSebrae = 0;
            this.valorTotal = 0;
            this.eixo = null
            this.subEixo = null;
        },

        closeDialog() {
            this.$emit('close');
            this.display = false;
        },

        salvar() {
            if(this.subEixo == null)
            {
                this.subEixo = 0
            }
            this.acao = {
                descricaoAcao: this.descricaoAcao,
                // valorCliente: this.valorCliente,
                valorSebrae: this.valorSebrae,
                valorTotal: this.valorTotal,
                eixo: this.eixo,
                subEixo: this.subEixo
            };
            this.$store.dispatch('addRequest');
                if(this.id != 0){
                    this.acao.acaoId = this.id;
                }
                this.acao.idParceria = this.parceria.id;
                Services.InserirOuAlterarAcaoParceria(this.id, this.acao).then((response) => {
                    if (response && response.success) {
                        this.$emit('atualizarLista');
                    } 
                    this.closeDialog();
                    this.$store.dispatch('removeRequest');
                    this.limpar();
                });
        },
    },

    computed: {
        openDialog() {
            return this.show || this.display;
        },
        podeCriarAlterarOuExcluirAcao() {
            return this.parceria.aditivoPendente || this.parceria?.status === 0;
        },
    },

    watch: {
        'defaultValue'() {
            this.descricaoAcao = this.defaultValue?.descricaoAcao;
            // this.valorCliente = this.defaultValue?.valorCliente;
            this.valorSebrae = this.defaultValue?.valorSebrae;
            this.valorTotal = this.defaultValue?.valorTotal;
            this.eixo = this.defaultValue?.eixo;
            this.subEixo = this.defaultValue?.subEixo;
            this.id = this.defaultValue?.acaoId;
            this.idParceria = this.defaultValue?.idParceria;
        },
        // valorCliente()
        // {
        //     if(this.valorCliente != null || this.valorCliente != 0)
        //     {
        //         this.valorTotal = 0;
        //         this.valorTotal = this.valorCliente + this.valorSebrae;
        //     }
        // },
        valorSebrae()
        {
            if(this.valorSebrae != null || this.valorSebrae != 0)
            {
                this.valorTotal = 0;
                this.valorTotal = this.valorSebrae;
            }
        },
    },
};
</script>