<template>
    <div class="grid">
        <div class="col-12">
            <tabela :data="lista">
                <template #botoes>
                    <CadParcelas
                        v-if="validarExclusaoParcelas"
                        :defaultValue="parcela"
                        :operacao="operacao"
                        :parceria="parceria"
                        :show="abrirFormularioParcela"
                        @close="onCloseFormularioParcela"
                        :parceriaAditivo="parceriaAditivo"
                        @atualizarLista="atualizaLista()"
                    ></CadParcelas>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex gap-1">
                                <font-awesome-icon
                                    v-if="validarExclusaoParcelas"
                                    class="icon-button"
                                    icon="trash-alt"
                                    size="lg"
                                    style="color: red"
                                    title="Deletar"
                                    @click="confirmaDelete(slotProps.data)"
                                />
                                <div class="mr-3"></div>
                                <font-awesome-icon
                                    v-if="validarExclusaoParcelas"
                                    class="icon-button p-text-info"
                                    icon="pen"
                                    size="lg"
                                    title="Editar"
                                    @click="onAbrirFormularioParcela(slotProps.data)"
                                />
                            </div>
                        </template>
                    </Column>
                    <Column :sortable="true" field="parcela" header="Parcela" headerStyle="width: 6em">
                        <template #body="slotProps">
                            {{ slotProps.data.parcela }}
                        </template>
                    </Column>
                    <Column :sortable="true" field="valor" header="Valor">
                        <template #body="slotProps">
                            {{ slotProps.data.valorFormatado }}
                        </template>
                    </Column>
                    <Column :sortable="true" field="dataVencimento" header="Vencimento">
                        <template #body="slotProps">
                            {{ slotProps.data.dataVencimentoFormatada }}
                        </template>
                    </Column>
                    <Column :sortable="true" field="formaPagamento" header="Forma de Pagamento">
                        <template #body="slotProps">
                            {{ slotProps.data.formaPagamento }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>

    <Dialog v-model:visible="showDialogExcluir" :modal="true" :style="{ width: '350px' }" header="Confirmação">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Não" @click="closeConfirmation(false)" />
            <Button autofocus class="p-button-text" icon="pi pi-check" label="Sim" @click="closeConfirmation(true)" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import CadParcelas from '../cadastro/CadParcelas.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },
        operacao: {
            type: String,
            default: 'PARCERIA',
        },
        parceriaAditivo: {
            type: Object,
        },
    },

    components: {
        CadParcelas,
    },

    data() {
        return {
            showDialogExcluir: false,
            objExcluir: {},
            lista: [],
            validarExclusaoParcelas: true,
            abrirFormularioParcela: false,
            parcela: null,
        };
    },

    methods: {
        onCloseFormularioParcela() {
            this.abrirFormularioParcela = false;
            this.parcela = null;
        },
        onAbrirFormularioParcela(parcela) {
            this.abrirFormularioParcela = true;
            this.parcela = parcela;
        },
        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        confirmaDelete(obj) {
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },
        deletar() {
            this.$store.dispatch('addRequest');

            if (this.operacao == 'PARCERIA') {
                Services.DeletarParcelasParceria(this.objExcluir)
                    .then((response) => {
                        if (response && response.success) {
                            this.atualizaLista();
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            } else {
                let parceriaAditivoParcela = {
                    parceriaAditivoParcelaId: this.objExcluir.id,
                    ParceriaAditivoId: this.objExcluir.parceriaId,
                };
                Services.DeletarParceriaAditivoParcelas(parceriaAditivoParcela)
                    .then((response) => {
                        if (response && response.success) {
                            this.atualizaLista();
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            }
        },
        atualizaLista() {
            if (this.operacao == 'PARCERIA') {
                this.$emit('atualizarLista', true);
            }
            this.$emit('atualizarListaAditivo', true);
        },
    },

    watch: {
        parceria() {
            this.lista = this.parceria.parcelas;
            if (
                this.parceria.status === 2 ||
                this.parceria.status === 3 ||
                this.parceria.status === 4 ||
                this.parceria.status === 6 ||
                this.parceria.status === 7 ||
                this.parceria.status === 8 ||
                this.parceria.status === 9 ||
                this.parceria.status === 10
            ) {
                this.validarExclusaoParcelas = false;
            }
        },

        parceriaAditivo() {
            this.lista = this.parceriaAditivo.parcelas;
            if (
                this.parceriaAditivo.status === 0 ||
                this.parceriaAditivo.status === 1 ||
                this.parceriaAditivo.status === 3 ||
                this.parceriaAditivo.status === 6
            ) {
                this.validarExclusaoParcelas = false;
            }
        },
    },

    computed: {
        podeCriarAlterarOuExcluirParcela() {
            return this.parceria.aditivoPendente || this.parceria.status === 0;
        },
    },

    mounted() {},
};
</script>
