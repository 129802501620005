<template>
    <Button v-if='!possuiAditivoAberto && parceria.status === 1' class='ml-2' icon='pi pi-plus' label='Adicionar'
            @click='showDialog()' />
    <Button v-else class='ml-2' icon='pi pi-pencil' label='Editar Aditivo'
            @click='showDialog()' />

    <Dialog v-model:visible='display' :maximizable='false' :style="{ width: '80vw' }" no-close-on-backdrop>
        <template #header>
            <h3>Cadastro - Aditivo</h3>
        </template>

        <div class='formgrid grid p-fluid'>
            <div class='field col-12'>
                <label class='required'>
                    Descreva o objetivo da alteração e o que será modificado por meio deste Aditivo
                </label>
                <Textarea v-model='observacao' :autoResize='true' cols='30' rows='5' />
                <small v-if='v$.observacao.$error' class='p-error'>O campo "Observações" é obrigatório</small>
            </div>
        </div>
        <template #footer>
            <Button :disabled='v$.$invalid' label='Salvar' @click='salvar()' />
            <Button autofocus label='Fechar' @click='closeDialog()' />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';


export default {
    props: {
        parceria: {
            type: Object,
        },

        possuiAditivoAberto: {
            type: Boolean,
        },
    },

    components: {},

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            observacao: { required },
        };
    },

    data() {
        return {
            display: false,
            parcela: null,
            idAditivo : null,
            observacao: '',
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.limpar();
            if(this.possuiAditivoAberto){
                this.observacao = this.parceria.aditivos[0].observacao;
                this.idAditivo = this.parceria.aditivos[0].id;
            }
        },

        limpar() {
            this.observacao = null;
        },

        closeDialog() {
            this.limpar();
            this.display = false;
        },

        salvar() {
            let aditivo = {
                parceriaId: this.parceria.id,
                Observacao: this.observacao,
            };
            this.$store.dispatch('addRequest');
            if(this.possuiAditivoAberto){
                aditivo.Id = this.idAditivo;
                Services.AlterarParceriaAditivo(aditivo).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }
            }).finally(() => {
                this.$store.dispatch('removeRequest');
                this.limpar();
                this.closeDialog();
            });
            } else {
                Services.InserirParceriaAditivo(aditivo).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }
            }).finally(() => {
                this.$store.dispatch('removeRequest');
                this.limpar();
                this.closeDialog();
            });
            }
        },
    },
    mounted() {
    },
};
</script>