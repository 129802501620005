<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5>Testemunhas</h5>
                </div>
                <tabela :data="lista">
                    <template #botoes>
                        <CadTestemunhas
                            v-if="validarExclusao"
                            :parceria="parceria"
                            :operacao="operacao"
                            :parceriaAditivoId="parceriaAditivo?.id"
                            @atualizarLista="atualizarLista()"
                        ></CadTestemunhas>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <font-awesome-icon
                                    v-if="validarExclusao"
                                    icon="trash-alt"
                                    @click="confirmarExclusao(slotProps.data.id)"
                                    size="lg"
                                    class="icon-button"
                                    title="Deletar"
                                    style="color: red"
                                />
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.nome }}
                            </template>
                        </Column>
                        <Column field="email" header="Email" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.email }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
</template>

<script>
import Services from '../../service.js';
import CadTestemunhas from '../cadastro/CadTestemunhas.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },

        operacao: {
            type: String,
            default: 'PARCERIA',
        },

        parceriaAditivo: {
            type: Object,
        },

        testemunhas: {
            type: Array,
        },
    },

    emits: ['atualizarLista'],

    components: {
        CadTestemunhas,
    },

    data() {
        return {
            mostrarDialogExcluir: false,
            objExcluir: null,
            lista: [],
            validarExclusao: true,
        };
    },

    methods: {
        confirmarExclusao(id) {
            this.$swal({
                title: 'Excluir',
                html: 'Deseja Excluir a Testemunha?',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then((result) => {
                if (result.value) {
                    this.deletar(id);
                }
            });
        },

        deletar(id) {
            let testemunhaDto = {
                idParceria: this.parceria.id,
                idTestemunha: id,
            };
            this.$store.dispatch('addRequest');
            Services.deletarTestemunhaParceria(testemunhaDto)
                .then(() => {
                    this.atualizarLista();
                    this.$store.dispatch('removeRequest');
                })
                .catch(() => {
                    this.$store.dispatch('removeRequest');
                    this.$swal({
                        title: 'Erro!',
                        text: 'Erro ao excluir Testemunha!',
                        icon: 'error',
                        timer: 2000,
                    });
                });
        },

        atualizarLista() {
            if (this.operacao == 'PARCERIA') {
                this.$emit('atualizarLista', true);
            }
            this.$emit('atualizarListaAditivo', true);
        },
    },

    watch: {
        testemunhas: {
            handler: function (val) {
                this.lista = val;
            },
            immediate: true,
        },

        parceria() {
            if (
                this.parceria.status === 2 ||
                this.parceria.status === 3 ||
                this.parceria.status === 4 ||
                this.parceria.status === 6 ||
                this.parceria.status === 7 ||
                this.parceria.status === 8 ||
                this.parceria.status === 9 ||
                this.parceria.status === 10
            ) {
                this.validarExclusao = false;
            }
        },

        parceriaAditivo() {
            if (
                this.parceriaAditivo.status === 0 ||
                this.parceriaAditivo.status === 1 ||
                this.parceriaAditivo.status === 3 ||
                this.parceriaAditivo.status === 6
            ) {
                this.validarExclusao = false;
            }
        },
    },
};
</script>
