<template>
    <div class="grid p-fluid px-2 py-4">
        <div class="formgrid grid col-12">
            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <BCalendar
                    v-model="dataExecucaoInicial"
                    :desabilita="desabilita"
                    :mensagemErro="v$.dataExecucaoInicial.$silentErrors[0]?.$message || ''"
                    :temErro="v$.dataExecucaoInicial.$error"
                    label="Data Início Ações"
                    required
                    @blur="v$.dataExecucaoInicial.$touch()"
                />
            </div>

            <div class="field col-12 md:col-6 lg:col-3 xl:col-3">
                <BCalendar
                    v-model="dataExecucaoFinal"
                    :desabilita="desabilita"
                    :mensagemErro="v$.dataExecucaoFinal.$silentErrors[0]?.$message || ''"
                    :temErro="v$.dataExecucaoFinal.$error"
                    label="Data Execução Final"
                    required
                    @blur="v$.dataExecucaoFinal.$touch()"
                />
            </div>

            <div class="field col-3">
                <BCalendar
                    v-model="dataInicioParceria"
                    :desabilita="desabilita"
                    :mensagemErro="v$.dataInicioParceria.$silentErrors[0]?.$message || ''"
                    :temErro="v$.dataInicioParceria.$error"
                    label="Data Início"
                    required
                    @blur="v$.dataInicioParceria.$touch()"
                />
            </div>
            <div class="field col-3">
                <BCalendar
                    v-model="dataFimParceria"
                    :desabilita="desabilita"
                    :mensagemErro="v$.dataFimParceria.$silentErrors[0]?.$message || ''"
                    :temErro="v$.dataFimParceria.$error"
                    label="Data Fim"
                    required
                    @blur="v$.dataFimParceria.$touch()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    props: {
        parceria: {
            type: Object,
            default: null,
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            dataExecucaoInicial: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMenorQueFinal: helpers.withMessage('Não pode ser maior que a data final', (val) => {
                    const dataInicio = this.$moment(val, 'DD/MM/YYYY');
                    const dataFim = this.$moment(this.dataExecucaoFinal, 'DD/MM/YYYY');
                    return dataInicio.isSameOrBefore(dataFim);
                }),
            },
            dataExecucaoFinal: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMaiorQueInicial: helpers.withMessage('Não pode ser menor que a data de início', (val) => {
                    const dataFim = this.$moment(val, 'DD/MM/YYYY');
                    const dataInicio = this.$moment(this.dataExecucaoInicial, 'DD/MM/YYYY');
                    return dataFim.isSameOrAfter(dataInicio);
                }),
            },
            dataInicioParceria: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMenorQueFinal: helpers.withMessage('Não pode ser maior que a data final', (val) => {
                    const dataInicio = this.$moment(val, 'DD/MM/YYYY');
                    const dataFim = this.$moment(this.dataFimParceria, 'DD/MM/YYYY');
                    return dataInicio.isSameOrBefore(dataFim);
                }),
            },
            dataFimParceria: {
                required: helpers.withMessage('Campo obrigatório', required),
                dataValida: helpers.withMessage('Não é uma data válida', (val) => this.$moment(val, 'DD/MM/YYYY').isValid()),
                dataMaiorQueInicial: helpers.withMessage('Não pode ser menor que a data de início', (val) => {
                    const dataFim = this.$moment(val, 'DD/MM/YYYY');
                    const dataInicio = this.$moment(this.dataInicioParceria, 'DD/MM/YYYY');
                    return dataFim.isSameOrAfter(dataInicio);
                }),
            },
        };
    },
    data() {
        return {
            dataExecucaoInicial: null,
            dataExecucaoFinal: null,
            dataInicioParceria: null,
            dataFimParceria: null,
        };
    },
    methods: {
        atualizaValores() {
            this.$emit('update:valores', {
                isValid: !this.v$.$invalid,
                dataExecucaoInicial: this.dataExecucaoInicial,
                dataExecucaoFinal: this.dataExecucaoFinal,
                dataInicioParceria: this.dataInicioParceria,
                dataFimParceria: this.dataFimParceria,
            });
        },
    },
    watch: {
        'parceria.dataExecussaoInicialFormatada'() {
            this.dataExecucaoInicial = this.parceria?.dataExecussaoInicialFormatada;
        },
        'parceria.dataExecussaoFinalFormatada'() {
            this.dataExecucaoFinal = this.parceria?.dataExecussaoFinalFormatada;
        },
        'parceria.dataInicioParceriaFormatada'() {
            this.dataInicioParceria = this.parceria?.dataInicioParceriaFormatada;
        },
        'parceria.dataFimParceriaFormatada'() {
            this.dataFimParceria = this.parceria?.dataFimParceriaFormatada;
        },
        dataExecucaoInicial() {
            this.atualizaValores();
        },
        dataExecucaoFinal() {
            this.atualizaValores();
        },
        dataInicioParceria() {
            this.atualizaValores();
        },
        dataFimParceria() {
            this.atualizaValores();
        },
    },
};
</script>
