<template>
    <LayoutBase :breadcrumbs="breadcrumbs" home="/mapaparceiros/exportacaoxls">
        <h3>Detalhes - Acordo de Parceria</h3>

        <TabView>
            <TabPanel header="Dados Gerais">
                <AbaDadosGerais :parceria="parceria" />
            </TabPanel>
            <TabPanel header="Cláusulas">
                <AbaClausulas :desabilita="!parceria.aditivoPendente" :parceria="parceria" @update:valores="atualizaClausulas" />
            </TabPanel>
            <TabPanel header="Unidades Regionais">
                <div class="grid">
                    <div class="col-12">
                        <tabela :data="parceria.unidadesRegionaisParceria">
                            <template #botoes>
                                <CadUnidades v-if="validarStatusParceria" :parceria="parceria" @atualizarLista="obterParceria()"></CadUnidades>
                            </template>
                            <template #conteudo>
                                <Column headerStyle="width: 3em">
                                    <template #body="slotProps">
                                        <font-awesome-icon
                                            v-if="validarStatusParceria"
                                            class="icon-button"
                                            icon="trash-alt"
                                            size="lg"
                                            style="color: red"
                                            title="Deletar"
                                            @click="confirmaDeleteUnidadesRegionais(slotProps.data)"
                                        />
                                    </template>
                                </Column>
                                <Column :sortable="true" field="unidadeRegional.id" header="Id" headerStyle="width: 6em">
                                    <template #body="slotProps">
                                        {{ slotProps.data.unidadeRegional.id }}
                                    </template>
                                </Column>
                                <Column :sortable="true" field="unidadeRegional.descricao" header="Descrição">
                                    <template #body="slotProps">
                                        {{ slotProps.data.unidadeRegional.descricao }}
                                    </template>
                                </Column>
                            </template>
                        </tabela>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Interlocutores">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div class="flex">
                                <h5>Interlocutores</h5>
                            </div>
                            <tabela :data="parceria.interlocutoresParceria">
                                <template #botoes>
                                    <CadInterlocutor v-if="validarStatusParceria" :parceria="parceria" @atualizarLista="obterParceria()"></CadInterlocutor>
                                </template>
                                <template #conteudo>
                                    <Column headerStyle="width: 3em">
                                        <template #body="slotProps">
                                            <font-awesome-icon
                                                v-if="validarStatusParceria"
                                                class="icon-button"
                                                icon="trash-alt"
                                                size="lg"
                                                style="color: red"
                                                title="Deletar"
                                                @click="confirmaDeleteInterlocutores(slotProps.data)"
                                            />
                                        </template>
                                    </Column>
                                    <Column :sortable="true" field="interlocutor.id" header="Id" headerStyle="width: 6em">
                                        <template #body="slotProps">
                                            {{ slotProps.data.interlocutor.id }}
                                        </template>
                                    </Column>
                                    <Column :sortable="true" field="interlocutor.nome" header="Nome">
                                        <template #body="slotProps">
                                            {{ slotProps.data.interlocutor.nome }}
                                        </template>
                                    </Column>
                                </template>
                            </tabela>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Analistas e Gerentes">
                <ListaDemaisEnvolvidos :parceria="parceria" @atualizarLista="obterParceria()"></ListaDemaisEnvolvidos>
            </TabPanel>
            <TabPanel header="Testemunhas">
                <ListaTestemunhas :parceria="parceria" :testemunhas="parceria.testemunhas" @atualizarLista="obterParceria()"></ListaTestemunhas>
            </TabPanel>
            <TabPanel header="Parcelas">
                <ListaParcelas :parceria="parceria" @atualizarLista="obterParceria()"></ListaParcelas>
            </TabPanel>
            <TabPanel header="Ações">
                <ListaAcoes :parceria="parceria" @atualizarLista="obterParceria()"></ListaAcoes>
            </TabPanel>

            <TabPanel header="Datas">
                <AbaDatas :desabilita="!parceria.aditivoPendente" :parceria="parceria" @update:valores="atualizaDatas" />
            </TabPanel>
            <TabPanel header="Valores Envolvidos">
                <AbaValores :desabilita="!parceria.aditivoPendente" :parceria="parceria" @update:valores="atualizaValores" />
            </TabPanel>
            <TabPanel header="Perfis">
                <AbaPerfis :parceria="parceria" :desabilita="!parceria.aditivoPendente" @update:valores="atualizaPerfis" />
            </TabPanel>
            <TabPanel header="Aditivos">
                <ListaAditivos :parceria="parceria" @atualizarLista="obterParceria()"></ListaAditivos>
            </TabPanel>

            <TabPanel header="Histórico">
                <AbaTimeline :timelines="parceria.timelines" />
            </TabPanel>
        </TabView>

        <Divider />

        <!-- Footer -->
        <div class="flex flex-wrap gap-3">
            <div class="flex-1"></div>
            <Button v-if="parceria.aditivoPendente" :disabled="!isValid" @click="onAditivoConcluido">Concluir Aditivo </Button>
        </div>

        <Dialog v-model:visible="showDialogExcluir" :modal="true" :style="{ width: '350px' }" header="Confirmação">
            <div class="confirmation-content">
                <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
                <span>Confirma Exclusão?</span>
            </div>
            <template #footer>
                <Button class="p-button-text" icon="pi pi-times" label="Não" @click="closeConfirmation(false)" />
                <Button autofocus class="p-button-text" icon="pi pi-check" label="Sim" @click="closeConfirmation(true)" />
            </template>
        </Dialog>
    </LayoutBase>
</template>

<script>
import Services from '../../service.js';
import CadUnidades from '../cadastro/CadUnidadesRegionaisParceria.vue';
import CadInterlocutor from '../cadastro/CadInterlocutoresParceria.vue';
import ListaDemaisEnvolvidos from '../lista/ListaDemaisEnvolvidos.vue';
import ListaParcelas from '../lista/ListaParcelas.vue';
import ListaAcoes from '../lista/ListaAcoes.vue';
import ListaAditivos from '../lista/ListaAditivos.vue';
import ListaTestemunhas from '../lista/ListaTestemunhas.vue';
import LayoutBase from '../layout/LayoutBase.vue';
import AbaValores from './AbaValores.vue';
import AbaDatas from './AbaDatas.vue';
import AbaDadosGerais from './AbaDadosGerais.vue';
import AbaPerfis from './AbaPerfis.vue';
import AbaTimeline from './AbaTimeline.vue';
import AbaClausulas from './AbaClausulas.vue';

export default {
    props: {
        idParceria: {
            type: Number,
        },
    },

    components: {
        AbaTimeline,
        AbaPerfis,
        AbaDadosGerais,
        CadUnidades,
        CadInterlocutor,
        ListaDemaisEnvolvidos,
        ListaParcelas,
        ListaAcoes,
        ListaAditivos,
        LayoutBase,
        AbaValores,
        AbaDatas,
        AbaClausulas,
        ListaTestemunhas,
    },

    data() {
        return {
            breadcrumbs: [
                { label: 'Listagem de Parcerias', to: '/mapaparceiros/parceria' },
                { label: 'Detalhes da Parceria', to: `/mapaparceiros/parceria/detalhar/${this.$route.params.id}` },
            ],
            showDialogExcluir: false,
            objExcluir: {},
            tabelaDelete: '',
            datas: {
                isValid: false,
                dataExecucaoInicial: null,
                dataExecucaoFinal: null,
                dataInicioParceria: null,
                dataFimParceria: null,
            },
            valores: {
                isValid: false,
                valorReceita: null,
                valorContrapartidaFinanceira: null,
                valorContrapartidaEconomica: null,
                valorAporteSebrae: null,
            },
            clausulas: {
                isValid: false,
                competeParceiro: null,
                competeSebrae: null,
            },
            perfis: {
                isValid: false,
                aproximaSebraePublicoPretendido: false,
                agregaExpertiseTecnica: false,
                representacaoSetorialEmpresarial: false,
                promoveCreditoParaEmpresas: false,
                promoveMercadoPequenosNegocios: false,
                temFocoInovacaoEmpreendedorismo: false,
                conexaoCorporativaEmpresaGrandePorteGov: false,
            },
            parceria: {
                parceiro: {
                    areaAtuacaoPrincipal: {},
                    setor: {},
                },
                // modalidadeApoio: {},
                unidadesRegionaisParceria: [],
                aditivoPendente: false,
            },

            validarStatusParceria: true,
        };
    },

    methods: {
        atualizaDatas(data) {
            this.datas = data;
        },
        atualizaValores(data) {
            this.valores = data;
        },
        atualizaClausulas(data) {
            this.clausulas = data;
        },
        atualizaPerfis(data) {
            this.perfis = data;
        },
        onAditivoConcluido() {
            const data = {
                dataExecussaoInicial: this.$moment(this.datas.dataExecucaoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataExecussaoFinal: this.$moment(this.datas.dataExecucaoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataInicioParceria: this.$moment(this.datas.dataInicioParceria, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                dataFimParceria: this.$moment(this.datas.dataFimParceria, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                valorReceita: this.valores.valorReceita,
                valorContrapartidaFinanceira: this.valores.valorContrapartidaFinanceira,
                valorContrapartidaEconomica: this.valores.valorContrapartidaEconomica,
                valorAporteSebrae: this.valores.valorAporteSebrae,
                competeSebrae: this.clausulas.competeSebrae,
                competeParceiro: this.clausulas.competeParceiro,
                aproximaSebraePublicoPretendido: this.perfis.aproximaSebraePublicoPretendido,
                agregaExpertiseTecnica: this.perfis.agregaExpertiseTecnica,
                representacaoSetorialEmpresarial: this.perfis.representacaoSetorialEmpresarial,
                promoveCreditoParaEmpresas: this.perfis.promoveCreditoParaEmpresas,
                promoveMercadoPequenosNegocios: this.perfis.promoveMercadoPequenosNegocios,
                temFocoInovacaoEmpreendedorismo: this.perfis.temFocoInovacaoEmpreendedorismo,
                conexaoCorporativaEmpresaGrandePorteGov: this.perfis.conexaoCorporativaEmpresaGrandePorteGov,
            };
            this.$store.dispatch('addRequest');
            Services.ConcluirAditivo(this.$route.params.id, data)
                .then((response) => {
                    if (response && response.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'Alteração de parceria concluída com sucesso',
                            life: 3000,
                        });
                        this.obterParceria();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Erro',
                            detail: 'Erro ao tentar alterar a parceria',
                            life: 5000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        obterParceria() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            Services.SelectByIdParceriasReplace(id)
                .then((response) => {
                    if (response && response.success) {
                        this.parceria = response.data;
                        this.validarExclucao();
                    } else {
                        this.parceria = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        validarExclucao() {
            if (
                this.parceria.status === 2 ||
                this.parceria.status === 3 ||
                this.parceria.status === 4 ||
                this.parceria.status === 6 ||
                this.parceria.status === 7 ||
                this.parceria.status === 8 ||
                this.parceria.status === 9 ||
                this.parceria.status === 10
            ) {
                this.validarStatusParceria = false;
            }
        },

        closeConfirmation(confirmacao) {
            if (confirmacao) {
                if (this.tabelaDelete === 'Unidades') {
                    this.deletarUnidadesRegionais();
                } else if (this.tabelaDelete === 'interlocutores') {
                    this.deletarInterlocutores();
                }
            }
            this.showDialogExcluir = false;
        },

        confirmaDeleteUnidadesRegionais(obj) {
            this.tabelaDelete = 'Unidades';
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },

        confirmaDeleteInterlocutores(obj) {
            this.tabelaDelete = 'interlocutores';
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },

        deletarUnidadesRegionais() {
            this.$store.dispatch('addRequest');
            Services.DeletarUnidadesRegionaisParceria(this.objExcluir).then((response) => {
                if (response && response.success) {
                    this.obterParceria();
                }
                this.$store.dispatch('removeRequest');
            });
        },

        deletarInterlocutores() {
            this.$store.dispatch('addRequest');
            Services.DeletarInterlocutoresParceria(this.objExcluir).then((response) => {
                if (response && response.success) {
                    this.obterParceria();
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    computed: {
        isValid() {
            return this.valores.isValid && this.datas.isValid;
        },
    },

    mounted() {
        this.obterParceria();
    },
};
</script>
