<template>
    <div class='pt-4 pr-8'>

        <Timeline :value='timelines'>
            <template #opposite='slotProps'>
                <small>{{ $moment(slotProps.item.data).format('DD/MM/YYYY HH:mm') }}</small>
            </template>
            <template #content='slotProps'>
                {{ slotProps.item.evento }}
            </template>
        </Timeline>
    </div>
</template>

<script>

export default {
    props: {
        timelines: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
};

</script>